import {
    Button,
    Link
} from "@fluentui/react-components";
import { HeaderVideoLink, StringLabel } from "../common/constants";
import labels from '../common/FTE.string';
import { classNames } from "./common.style";

const Header = () => {

    return <header className={classNames.header}>
        <h1>{labels.header.fastTrackEngineering}</h1>
        <h6>
            {labels.header.latestUpdate}
        </h6>
        <div>
            <Link href={HeaderVideoLink} target={StringLabel.Blank}>
                <Button appearance="primary" className={classNames.usingThisRoadMap}>
                    {labels.header.roadMapButton}
                </Button>
            </Link>
        </div>
    </header>
}

export default Header;
