import { Epic, Feature, RoadMapItem, RoadMapList } from "../model";
import {
  AllStatus,
  EpicFilters,
  InitiativeFilters,
  InvestmentCategory,
  Quarters,
  SortedChangeType,
  TargetReleaseLabel,
  aiFilter
} from "./constants";

export const getFilterArrayByKeys = (
  RoadMaps: RoadMapItem[],
  keys: string[],
  currentFilter: string,
  filtersList: any
) => {
  const filters: any = {};
  keys?.forEach((key) => {
    if (InitiativeFilters?.includes(key)) {
      if (key === "investmentCategory") {
        const investmentCategoryEnumValues = new Set(Object.values(InvestmentCategory));
    
        // Extract and process categories
        filters[key] = [
            ...new Set(
                RoadMaps?.flatMap((item: any) =>
                    item[key]
                        ? item[key]?.split(";").map((item: any) => item.trim())
                        : []
                ).filter((category: any) =>
                    investmentCategoryEnumValues.has(category) // Replace non-enum categories with "Other"
                )
            )
        ].sort((a, b) => a.localeCompare(b));
    }
     else {
      filters[key] = [
        ...new Set(
          RoadMaps?.flatMap((item: any) =>
            item[key]
              ? item[key]?.split(";").map((item: any) => item.trim())
              : []
          )
        ),
      ].sort((a, b) => a.localeCompare(b));
    }
    } else if (EpicFilters.includes(key)) {
      if (key === "ai") {
        filters[key] = aiFilter;
      } else {
      filters[key] = [
        ...new Set(
          RoadMaps.flatMap((item) =>
            item.epics
              .map((epic: any) => epic[key]?.split(";"))
              .flat(1)
              .map((filter: any) => filter?.trim())
              .filter(filter => filter.trim() !== '')
          )
        ),
      ].sort((a, b) => a.localeCompare(b));
    }
    } else {
      if (key === TargetReleaseLabel) {
        filters[key] = [...new Set(RoadMaps?.flatMap((a) => a.epics)
          .flatMap((a) => a.features)
          .map((a) => getFormatedQuarter(a))
        ),
        ].sort();
      } else {
        filters[key] = [
          ...new Set(
            RoadMaps.flatMap((item) =>
              item.epics.flatMap((epic) =>
                epic.features.flatMap(
                  (feature: any) =>
                    feature[key]
                      ?.split(";") // Split by commas or semicolons with optional spaces
                      .map((filter: any) => filter?.trim()) // Trim whitespace from each scenario
                      .filter((filter: any) => filter?.length > 0) // Remove empty strings
                )
              )
            )
          ),
        ].sort((a, b) => a.localeCompare(b));
      }
    }

    if (currentFilter === key) {
      filters[key] = filtersList[key];
    }
  });

  filters.changeType = filters?.changeType?.sort((a: string, b: string) => {
    const aIndex = SortedChangeType?.indexOf(a);
    const bIndex = SortedChangeType?.indexOf(b);
    return aIndex - bIndex;
  });
  return filters;
};

function agentHas(keyword: string) {
  return navigator.userAgent.toLowerCase().search(keyword.toLowerCase()) > -1;
}

export function isEdge() {
  return agentHas("Edg");
}

export function getLastTwoDigitsOfYear(): number {
  const date = new Date();
  const fullYear = date.getFullYear();
  const month = date.getMonth();
  const fiscalYearStartMonth = 6;
  let fiscalYear = fullYear + 1;
  if (month < fiscalYearStartMonth) {
    fiscalYear = fullYear - 1;
  }

  const lastTwoDigits = fiscalYear % 100;
  return lastTwoDigits;
}

export function containsSearchString(
  item: any,
  searchString: string,
  wholeWordMatch: boolean
): boolean {
  try {
    if (searchString !== "") {
      if (typeof item === "object" && item !== null) {
        return Object.values(item).some((value) => {
          value = value?.toString();
          if (typeof value === "string") {
            if (wholeWordMatch) {
              let newSearchText = searchString;
              if (searchString?.includes("[")) {
                newSearchText = searchString?.replace(/\[/g, "!");
              }

              return new RegExp(
                `\\b${newSearchText?.trim().toLowerCase()}\\b`
              ).test(value?.toLowerCase());
            }
            return value
              .toLowerCase()
              .includes(searchString?.trim().toLowerCase());
          }
        });
      }
    }
  } catch {
    return false;
  }
  return false;
}

export function filterFeatures(
  features: Feature[],
  searchString: string,
  wholeWordMatch: boolean
): Feature[] {
  return features.filter((feature) =>
    containsSearchString(feature, searchString, wholeWordMatch)
  );
}

export function filterEpics(
  epics: Epic[],
  searchString: string,
  wholeWordMatch: boolean
): Epic[] {
  return epics
    .map((epic) => {
      const matchedEpic = containsSearchString(
        epic,
        searchString,
        wholeWordMatch
      );

      return {
        ...epic,
        features: matchedEpic
          ? epic?.features
          : filterFeatures(epic?.features, searchString, wholeWordMatch),
      };
    })
    .filter(
      (epic) =>
        epic?.features?.length > 0 ||
        containsSearchString(epic, searchString, wholeWordMatch)
    );
}

export function filterRoadMapItems(
  records: RoadMapItem[],
  searchString: string,
  wholeWordMatch: boolean
): RoadMapItem[] {
  return records
    .map((record) => {
      const matchedInitiative = containsSearchString(
        record,
        searchString,
        wholeWordMatch
      );

      return {
        ...record,
        epics: matchedInitiative
          ? record?.epics
          : filterEpics(record?.epics, searchString, wholeWordMatch),
      };
    })
    .filter(
      (record) =>
        record?.epics?.length > 0 ||
        containsSearchString(record, searchString, wholeWordMatch)
    );
}


export const filterEpicData = (
  roadMapItems: RoadMapItem[],
  checkedFilters: any,
  key: string
) => {
  if (checkedFilters[key].length === 0) return roadMapItems;

  return roadMapItems
    .map((item) => ({
      ...item,
      epics: item?.epics?.filter((epic: any) =>
        epic[key]
          .split(";")
          .map((epic: any) => epic?.trim())
          .some((filter: any) => checkedFilters[key]?.includes(filter))
      ),
    }))
    .filter((item) => item?.epics?.length > 0);
};

export const filterFeatureData = (
  roadMapItems: RoadMapItem[],
  checkedFilters: any,
  key: string
) => {
  if (checkedFilters[key].length === 0) return roadMapItems;
  if(key === TargetReleaseLabel) {
// Map and filter the roadmap items
    return roadMapItems
      ?.map((item) => ({
        ...item,
        epics: item?.epics
          ?.map((epic) => ({
            ...epic,
            features: epic?.features?.filter((feature) => {
              const yearQuarter = getFormatedQuarter(feature);
              return checkedFilters[key]?.includes(yearQuarter);
            }),
          }))
          .filter((epic) => epic?.features?.length > 0),
      }))
      .filter((item) => item?.epics?.length > 0);
  }
  return roadMapItems
    ?.map((item) => ({
      ...item,
      epics: item?.epics
        ?.map((epic) => ({
          ...epic,
          features: epic?.features?.filter((feature: any) =>
            feature[key]
              .split(";")
              .map((featureItem: any) => featureItem?.trim())
              .some((featureItem: any) =>
                checkedFilters[key]?.includes(featureItem)
              )
          ),
        }))
        ?.filter((epic) => epic.features.length > 0),
    }))
    ?.filter((item) => item.epics.length > 0);
};

export const sortRoadMapItems = (filterItems: RoadMapItem[], SortingOrder: any) => {
  if (SortingOrder.length > 0 && SortingOrder === "A-Z") {
    return filterItems
      .map((item: RoadMapItem) => {
        return {
          ...item,
          epics: item?.epics?.sort((a, b) =>
            a?.roadmapItemName?.localeCompare(b?.roadmapItemName, "es", {
              sensitivity: "base",
            })
          ),
        };
      })
      .sort((a, b) =>
        a?.initiativeName?.localeCompare(b.initiativeName, "es", {
          sensitivity: "base",
        })
      );
  }

  if (SortingOrder.length > 0 && SortingOrder === "Z-A") {
    return filterItems
      ?.map((item: RoadMapItem) => {
        return {
          ...item,
          epics: item?.epics?.sort((a, b) =>
            b?.roadmapItemName?.localeCompare(a?.roadmapItemName, "es", {
              sensitivity: "base",
            })
          ),
        };
      })
      .sort((a, b) =>
        b?.initiativeName?.localeCompare(a?.initiativeName, "es", {
          sensitivity: "base",
        })
      );
  }
}

export const getFormatedQuarter = (feature: Feature) => {
  return feature?.financialQuarter ? `${feature?.financialYear}/${feature?.financialQuarter}` : feature?.financialYear;
}

export const getFormatedDate = (date: string) => {
  date = date + 'z';
  const utcDate = new Date(date);
  let formatedDate: Intl.DateTimeFormat;
  let formattedDate = '';
  if (!isNaN(utcDate.getTime())) {
    const options: Intl.DateTimeFormatOptions = {
      timeZone:  Intl.DateTimeFormat().resolvedOptions().timeZone,
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false // 24-hour format
    };
    formatedDate = new Intl.DateTimeFormat(Intl.DateTimeFormat().resolvedOptions().locale, options);
    const parts = formatedDate.formatToParts(utcDate);

    // Extract date and time components
    const day = parts.find(p => p.type === 'day')?.value || '';
    const month = parts.find(p => p.type === 'month')?.value || '';
    const year = parts.find(p => p.type === 'year')?.value || '';
    const hour = parts.find(p => p.type === 'hour')?.value || '';
    const minute = parts.find(p => p.type === 'minute')?.value || '';
    formattedDate = `${day}-${month.toUpperCase().substring(0,3)}-${year} ${hour}:${minute}`;
  }
  return formattedDate;
}

export const getClassName = (state: string) => {
  const status= AllStatus.find((status) => status.statusList.includes(state));
  return status?.color;
}

export const setColumn = (roadMap: RoadMapList) => {
  const allColumns = [
    ...new Set(
      roadMap?.RoadMaps
        ? roadMap?.RoadMaps?.flatMap((a) => a.epics)
          .flatMap((a) => a.features)
          .map((a) => getFormatedQuarter(a))
        : []
    ),
  ].sort();

  const currentYear = getLastTwoDigitsOfYear();
  let currentYearColumns = allColumns?.filter((a) =>
    //a.includes(currentYear?.toString())
    a.includes(currentYear?.toString()) && Quarters.includes(a.split("/")[1])
  );

  const otherColumns = allColumns
  ?.filter((col) => !currentYearColumns?.includes(col))
  .sort();

if (currentYearColumns.length < 4) {
  for (let i = 0; i < otherColumns.length; i++) {
    if (currentYearColumns.length >= 4) {
      break;
    }
    currentYearColumns.push(otherColumns[i]);
  }
} else {
  currentYearColumns = currentYearColumns.slice(0, 4);
}

  return {
    allColumns: allColumns,
    columns: currentYearColumns,
  };
}

export const replaceValue = (value: string, enumObj: any): string => {
  return enumObj[value as keyof typeof enumObj] || value;
}; 

export const replaceValuesWithEnum = (values: string, enumObj: any): string => {
  return values
      .split(";") 
      .map(value => value.trim()) 
      .map(value => replaceValue(value, enumObj)) 
      .join(";");
};

export const removeHtmlTagsAndEntities = (values: string): string => {
  // Regular expression to remove HTML tags
  const htmlTagRegex = /<\/?[^>]+>/g;
  
  // Remove HTML tags
  let cleanedString = values.replace(htmlTagRegex, '');

  // Replace HTML entities with their corresponding characters
  cleanedString = cleanedString
      .replace(/&nbsp;/g, ' ')  // Non-breaking space
      .replace(/&quot;/g, '"')  // Quotation mark
      .replace(/&amp;/g, '&')   // Ampersand
      .replace(/&lt;/g, '<')    // Less-than sign
      .replace(/&gt;/g, '>');   // Greater-than sign

  return cleanedString;
};



export const  transformFeatureArray: (features: Feature[]) => any = (features: Feature[]) => {
  const featuresMap = new Map();

  features.forEach(feature => {
    const key = `${feature.financialYear}-${feature.financialQuarter || 'NoYear'}`;

    if (!featuresMap.has(key)) {
      featuresMap.set(key, {
        financialYear: feature.financialYear,
        financialQuarter: feature.financialQuarter || '',
        details: []
      });
    }

    featuresMap.get(key).details.push({
      ...feature
      
    });
  });
  return Array.from(featuresMap.values());
}

export const filterArrayByStatus = (filteredData: RoadMapItem[], checkedStatusLabels: any[]) => {
  return filteredData
  ?.map((item) => ({
    ...item,
    epics: item?.epics
      ?.map((epic) => ({
        ...epic,
        features: epic?.features?.filter((feature) => {
          
          return checkedStatusLabels?.includes(feature?.state);
        }),
      }))
      .filter((epic) => epic?.features?.length > 0),
  }))
  .filter((item) => item?.epics?.length > 0)
}

export const getstatusCount = (allFeature: Feature[], statusList: any) => {
  return allFeature?.filter((feature: Feature) => statusList?.includes(feature?.state))?.length;
}
