import { useMemo } from "react";
import { Dropdown, Option, OptionOnSelectData, SelectionEvents } from "@fluentui/react-combobox";
import { StringLabel } from "../common/constants";
import labels from "../common/FTE.string";
import { classNames } from "./common.style";

const Sort = ({ sortingOrder, onSort }: { sortingOrder: string, onSort: (value: string) => void }) => useMemo(() => 
<div className={classNames.allignSort}>
    <Dropdown
        onOptionSelect={(
            event: SelectionEvents,
            data: OptionOnSelectData
        ) => {
            onSort(data?.optionValue || StringLabel.SortingOrder)
        }}
        placeholder={labels.SortBy}
        selectedOptions={[sortingOrder]}
        defaultSelectedOptions={[sortingOrder]}
        className={classNames.sortWidth}
    >
        <Option key={labels.Az} text={StringLabel?.SortingOrder} value={StringLabel.SortingOrder} defaultChecked>
            {StringLabel.SortingOrder}
        </Option>
        <Option key={labels.Za} text={StringLabel.ReverseSortingOrder} value={StringLabel.ReverseSortingOrder}>
            {StringLabel.ReverseSortingOrder}
        </Option>
    </Dropdown>
</div>, [sortingOrder])

export default Sort;
