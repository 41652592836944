import React, { Component } from "react";
import { withMsal, IMsalContext } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import Home from "./Home";

interface AuthProps {
  msalContext: IMsalContext;
}

interface AuthState {
  isLogin: boolean;
}

class AuthC extends Component<AuthProps, AuthState> {
  constructor(props: AuthProps) {
    super(props);
    this.state = {
      isLogin: false,
    };
  }

  componentDidUpdate(
    prevProps: Readonly<AuthProps>,
    prevState: Readonly<AuthState>,
    snapshot?: any
  ): void {
    const { instance } = this.props.msalContext;
    const isAuthenticated = this.props.msalContext.accounts.length > 0;

    if (
      !isAuthenticated &&
      this.props.msalContext.inProgress === InteractionStatus.None
    ) {
      instance
        .loginRedirect()
        .then(() => {
          this.setState({ isLogin: true });
        })
        .catch((err) => {
          this.setState({ isLogin: false });
          console.error("Login failed: ", err);
        });
    }
  }

  render() {
    const isAuthenticated = this.props.msalContext.accounts.length > 0;

    return <div>{isAuthenticated ? <Home /> : <></>}</div>;
  }
}

const Auth = withMsal(AuthC);
export default Auth;
