export const FiltersArray: { fKey: string, fLabel: string }[] = [{
    fKey: "investmentCategory",
    fLabel: "Investment Category"
},
{
    fKey: "initiativeName",
    fLabel: "Initiative Name"
},
{
    fKey: "solutionArea",
    fLabel: "Solution Area"
},
{
    fKey: "workloadScenario",
    fLabel: "Workload"
},
{
    fKey: "engPillar",
    fLabel: "Engineering Pillar"
},
{
    fKey: "ai",
    fLabel: "AI Flag"
},
{
    fKey: "changeType",
    fLabel: "Change Type"
},
{
    fKey: "targetRelease",
    fLabel: "Target Release"
}]

export const DefaultFilters = {
    investmentCategory: [],
    initiativeName: [],
    solutionArea: [],
    workloadScenario: [],
    engPillar: [],
    ai: [],
    changeType: [],
    targetRelease: [],
};

export const Themes = {
    Primary: {
        mainHeader: "#000",
        header: "#3f827a",
        roadmapItem: "#EEEd",
        targetRelease: "#cfdfdd",
    },
    Secondary: {
        mainHeader: "#7971ea",
        header: "#8e98f5",
        roadmapItem: "#dfe2fe",
        targetRelease: "#b1cbfa",
    },
    Tertiary: {
        mainHeader: "#465c73",
        header: "#c86b2d",
        roadmapItem: "#b5bdc4",
        targetRelease: "#fbeebf",
    },
}

export enum InvestmentCategory {
    RunTheBiz = "Run the Business",
    SolutionArea = "Solution Area",
    Transformational = "Transformational"
}

export const AllStatus = [
    {
        status: "Backlog",
        checked: false,
        color: "#555555",
        statusList: ["Backlog", "Portfolio Backlog"]
    },
    {
        status: "Funnel",
        checked: false,
        color: "#A9A9A9",
        statusList: ["Funnel"]
    },
    {
        status: "Reviewing",
        checked: false,
        color: "#ACE1AF",
        statusList: ["Reviewing"]
    },
    {
        status: "Analyzing",
        checked: false,
        color: "#1CAC78",
        statusList: ["Analyzing"]
    },
    {
        status: "Implementing",
        checked: false,
        color: "#00693E",
        statusList: ["Implementing"]
    },
    {
        status: "Done",
        checked: false,
        color: "#062a78",
        statusList: ["Done"]
    },
    {
        status: "Validation on Stage",
        checked: false,
        color: "#add8e6",
        statusList: ["Validation on Stage"]
    },
    {
        status: "Deploying on Production",
        checked: false,
        color: "#87ceeb",
        statusList: ["Deploying on Production"]
    },
    {
        status: "Releasing",
        checked: false,
        color: "#4682b4",
        statusList: ["Releasing"]
    },
    {
        status: "Retiring",
        checked: false,
        color: "#ff8000",
        statusList: ["Retiring"]
    },
    {
        status: "Removed",
        checked: false,
        color: "#ffbf80",
        statusList: ["Removed"]
    }
]

export const SortedChangeType = ["New", "Expand", "Maintain", "Reduce"];
export const HeaderVideoLink = "https://aka.ms/FTEngRoadmapVideo";
export const InitiativeFilters = ["investmentCategory", "initiativeName"];
export const EpicFilters = ["solutionArea", "ai", "changeType"];
export const aiFilter = ["Feature", "Product"];
export const FeatureFilters = ["engPillar", "workloadScenario", "targetRelease"];
export const TargetReleaseLabel = "targetRelease";
export const RoadmapURL = "https://dev.azure.com/O365fasttrack/FastTrack/_workitems/edit/";
export const Quarters = ["Q1", "Q2", "Q3", "Q4"];
export const StringLabel = {
    EmptyString: "",
    SortingOrder: "A-Z",
    ReverseSortingOrder: "Z-A",
    Primary: "Primary",
    ItemsPerPage: "20",
    FirstPage: "1",
    Blank: "_blank"
}