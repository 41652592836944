import { Dropdown, Option, OptionOnSelectData, SelectionEvents } from "@fluentui/react-components";
import { Themes } from "../common/constants";
import { currentTheme } from "../common/types";
import labels from "../common/FTE.string";

const ThemeSelector = ({ currentTheme, onSelectTheme }: { currentTheme: currentTheme, onSelectTheme: any }) => {

    return <Dropdown
        onOptionSelect={(
            event: SelectionEvents,
            data: OptionOnSelectData
        ) => onSelectTheme(data.optionValue)}
        placeholder={labels.chooseTheme}
        selectedOptions={[currentTheme]}
        defaultSelectedOptions={[currentTheme]}
        className="sort-width"
    >
        {Object.keys(Themes).map(theme => {
            return <Option key={theme} text={theme} value={theme} style={{ display: "flex" }}>
                {theme}
                <div style={{ display: "flex", marginLeft: "auto", }}>
                    <div style={{ width: 12, backgroundColor: (Themes as any)[theme].mainHeader as string }}>&nbsp;</div>
                    <div style={{ width: 12, backgroundColor: (Themes as any)[theme].header as string }}>&nbsp;</div>
                    <div style={{ width: 12, backgroundColor: (Themes as any)[theme].roadmapItem as string }}>&nbsp;</div>
                    <div style={{ width: 12, backgroundColor: (Themes as any)[theme].targetRelease as string }}>&nbsp;</div>
                </div>
            </Option>
        }
        )}
    </Dropdown>
};

export default ThemeSelector;
