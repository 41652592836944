import { RoadmapURL, StringLabel, Themes } from "../common/constants";
import { FilterTypes, currentTheme } from "../common/types";
import { Epic, Feature, RoadMapItem } from "../model";
import { getClassName, getFormatedQuarter } from "../common/utils";
import {
  classNames,
  getColumnWidth,
  getModernUiStyle,
  getMultipleFeatureUI,
} from "./common.style";
import labels from "../common/FTE.string";

const ModernUI = ({
  filterItems,
  currentTheme,
  columns,
  allEpics,
}: {
  checkedFilters: FilterTypes;
  filterItems: RoadMapItem[];
  currentTheme: currentTheme;
  columns: string[];
  allEpics: Epic[];
}) => {
  const styles = getModernUiStyle(columns, currentTheme);
  const allinitiatives = new Set(allEpics.map((item) => item.initiativeId));
  const allEpicsByInitiative = Array.from(allinitiatives).map((id) => {
    const initiative = filterItems.find(
      (initiativeItem) => initiativeItem.id === id
    );
    return {
      id: id,
      initiativeName: initiative?.initiativeName,
      investmentCategory: initiative?.investmentCategory,
      lastRunDateTime: initiative?.lastRunDateTime,
      epics: allEpics.filter((epic) => epic.initiativeId === id),
    } as RoadMapItem;
  });

  return (
    <div className={classNames.mainBox}>
      <h3>{labels.modernUi.fastTrackEngineering}</h3>
      <table className={classNames.tableWrapper}>
        <tr className={classNames.fHeader}>
          <th style={styles.th}>{labels.modernUi.roadMapItem}</th>
          {columns.map((column, index) => {
            if (index === 0) {
              return (
                <th style={styles.th}>
                  {column}
                  <div style={styles.triangleA}></div>
                </th>
              );
            } else {
              return (
                <th style={styles.th}>
                  <div style={styles.triangleB}></div>
                  {column}
                  <div style={styles.triangleA}></div>
                </th>
              );
            }
          })}
        </tr>
        {allEpicsByInitiative.map((item: RoadMapItem) => {
          return (
            <>
              <tr>
                <td colSpan={100} className={classNames.colsFilter}>
                  <div className={classNames.fName} style={styles.header}>
                    <b>{`${labels.modernUi.initiative}${labels.modernUi.colon}`}</b>
                    <a
                      href={`${RoadmapURL}${item?.id}`}
                      target={StringLabel.Blank}
                    >
                      {item?.id}
                    </a>
                    {labels.accordianWrapper.space}
                    {item?.initiativeName}
                  </div>
                </td>
              </tr>
              {item.epics.map((rItem: Epic) => {
                return (
                  <tr className={classNames.fRow}>
                    <td style={styles.roadmapItem}>
                      <span className={classNames.statusMargin}>
                        <span
                          className={`${classNames.square}`} style={{backgroundColor: getClassName(rItem.state)}}
                        ></span>
                      </span>
                      <b>{`${labels.modernUi.epic}${labels.modernUi.colon}`}</b>
                      <a
                        href={`${RoadmapURL}${rItem.id}`}
                        target={StringLabel.Blank}
                      >
                        {rItem?.id}
                      </a>
                      {labels.accordianWrapper.space}
                      {rItem.roadmapItemName}
                      <div><b>{`${labels.modernUi.solutionArea}${labels.modernUi.colon}`}</b>{rItem.solutionArea}</div>
                    </td>
                    {columns.map((column: string) => {
                      const columnData = rItem.features.filter((f) => {
                        const quarterTitle = getFormatedQuarter(f);
                        return quarterTitle === column;
                      });
                      if (columnData.length === 1) {
                        return (
                          <td style={styles.targetRelease}>
                            <span className={classNames.statusMargin}>
                              <span
                                className={`${classNames.square}`} style={{backgroundColor: getClassName(columnData[0].state)}}
                              ></span>
                            </span>
                            <b>{`${labels.modernUi.feature}${labels.modernUi.colon}`}</b>
                            <a
                              href={`${RoadmapURL}${columnData[0].id}`}
                              target={StringLabel.Blank}
                            >
                              {columnData[0].id}
                            </a>
                            {labels.accordianWrapper.space}
                            {columnData[0].scope}
                            <div><b>{`${labels.modernUi.workload}${labels.modernUi.colon}`}</b>{columnData[0].workloadScenario.replaceAll(';' , '; ')}</div>
                          </td>
                        );
                      }
                      return (
                        <td
                          style={getColumnWidth(
                            columns,
                            columnData,
                            currentTheme
                          )}
                        >
                          <div className={classNames.formatColumn}>
                            {columnData.map((f: Feature, index: number) => (
                              <div
                                className={classNames.divRow}
                                key={index}
                                style={getMultipleFeatureUI(
                                  f,
                                  columns,
                                  currentTheme
                                )}
                              >
                                <span className={classNames.statusMargin}>
                                  <span
                                    className={`${
                                      classNames.square
                                    }`} style={{backgroundColor: getClassName(f.state)}}
                                  ></span>
                                </span>
                                <b>{`${labels.modernUi.feature}${labels.modernUi.colon}`}</b>
                                <a
                                  href={`${RoadmapURL}${f.id}`}
                                  target={StringLabel.Blank}
                                >
                                  {f.id}
                                </a>
                                {labels.accordianWrapper.space}
                                {f.scope}
                                <div><b>{`${labels.modernUi.workload}${labels.modernUi.colon}`}</b>{f.workloadScenario.replaceAll(';' , '; ')}</div>
                              </div>
                            ))}
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </>
          );
        })}
      </table>
    </div>
  );
};

export default ModernUI;
