import axios from 'axios';
import { RoadMapItem } from './model';
 
const url = 'https://ftonboardapi.azurewebsites.net/api/FTERoadMap';
//const url = 'https://localhost:7050/api/FTERoadMap';
 
export async function getRoadMap(authToken: string): Promise<RoadMapItem[]>  {
  try {
    const config = {
      headers: { Authorization: `Bearer ${authToken}` }
    };
     const response = await axios.get(url, config);
     return response.data
  } catch (error) {
    return [];
  }
}