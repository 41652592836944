
const labels = {
    All: "All",
    LastUpdated: "Last Updated",
    searchString: "Search for a specific item:",
    wholeWordMatch: "Match whole word",
    filterLabel: "Filter the items below:",
    presentationView: "Presentation View",
    initiativeNameHeading: "Initiative Name - Epic Name",
    noDataFound: "No data found",
    chooseTheme: "Choose theme",
    microsoftLogo: "Microsoft Logo",
    SortBy: "Sort By",
    SortBy: "Sort By",
    Az: "AZ",
    Za: "ZA",
    switchDetailView: "Switch to detail view",
    switchPresentationView: "Switch to presentation view",
    
    header: {
        fastTrackEngineering: "FastTrack Engineering Roadmap",
        latestUpdate: " Get the latest updates from the FT Engineering team on the deployment automation, AI, and transformational investments that are helping simplify deployment journeys, accelerate usage, and drive revenue.",
        roadMapButton: "Using this roadmap",
    },
    accordianWrapper: {
        investmentCategories: "Investment Category :",
        initiativeName: "Initiative Name :",
        solutionArea: "Solution Area: ",
        aiFlag: "AI Flag:",
        changeType: "Change Type: ",
        corePriority: "Core Priority: ",
        scope: "Scope: ",
        descriptor: "Descriptor: ",
        engPillar: "Engineering Pillar: ",
        workload: "Workload: ",
        space: " ",
        targetRelease: "Target Release: ",
        all: "All",
    },
    modernUi: {
        fastTrackEngineering: "FASTTRACK ENGINEERING",
        roadMapItem: "Roadmap Item",
        hiphen: " - ",
        colon: ": ",
        initiative: "Initiative",
        epic: "Epic",
        feature: "Feature",
        solutionArea: "Solution Area",
        workload: "Workload",
    },
    filter: {
        selected: "selected",
    }
};

export default labels;