import React from "react";
import {
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
} from "@fluentui/react-components";

import { AccordianProps } from "../common/types";
import { getClassName, getFormatedQuarter, removeHtmlTagsAndEntities, transformFeatureArray } from "../common/utils";
import {  RoadmapURL, StringLabel } from "../common/constants";
import labels from '../common/FTE.string';
import { classNames } from "./common.style";

const AccordianWrapper = ({
  item,
  checkedFilters,
  value,
  epic,
}: AccordianProps) => {

  const transformFeatures = transformFeatureArray(epic.features);
  const epicCopy = JSON.parse(JSON.stringify(epic));
  epicCopy.features = transformFeatures;

  return (
    <AccordionItem value={value} className={classNames.accordian}>
      <AccordionHeader>
        <div className={`${classNames.flex} ${classNames.allignItemCenter} ${classNames.w100}`}>
          <div>
            <span className={`${classNames.square}`} style={{backgroundColor: getClassName(epic.state)}}></span>
          </div>
          <span className={classNames.accordianHeader}>
            {item?.initiativeName} -{labels.accordianWrapper.space}
            {
              <>  <a
              href={`${RoadmapURL}${epic.id}`}
              target={StringLabel?.Blank}>
              {epic?.id}
            </a> {epic?.roadmapItemName}  {labels.modernUi.hiphen}
               
              </>
            }
          </span>
          <div className={classNames.preview}>
            <strong>{labels.accordianWrapper.targetRelease}</strong>
            {checkedFilters?.targetRelease.length === 0
              ? [labels.accordianWrapper.all]
              : checkedFilters?.targetRelease?.join(", ")}
          </div>
        </div>
      </AccordionHeader>
      <AccordionPanel>
        <div className={classNames.accordianBody}>
          <div className={classNames.accordianSection}>
          {epicCopy.features.map((feature: any, index: any) => {
              const quarterTitle = getFormatedQuarter(feature);
              return  (
                <React.Fragment key={index}>
                  <div>
                    <strong>{`${quarterTitle}${labels.modernUi.colon}`}</strong>
                    {feature.details.map((detail: any, index: any) => {
                      return (
                        <React.Fragment key={index}>
                          <div className={classNames.epicMargin}>
                          <span className={`${classNames.circle} ${classNames.statusMargin}`}>
                            <span className={`${classNames.square}`} style={{backgroundColor: getClassName(detail.state)}}></span>
                          </span>
                            <strong>{labels.modernUi.feature}{labels.modernUi.colon}</strong>
                            <a
                              href={`${RoadmapURL}${detail?.id}`}
                              target={StringLabel.Blank}
                            >
                              {detail?.id}
                            </a>{labels.accordianWrapper.space}
                            {detail?.scope}
                          
                           
                          </div>
                          <div className={classNames.featureMargin}>
                            <strong>{labels.accordianWrapper.descriptor}</strong>
                            {removeHtmlTagsAndEntities(detail?.descriptor)}
                          </div>
                          <div className={classNames.featureMargin}>
                            <strong>{labels.accordianWrapper.engPillar}</strong>
                            {detail?.engPillar}
                          </div>
                          <div className={classNames.featureMargin}>
                            <strong>{labels.accordianWrapper.workload}</strong>
                            {detail?.workloadScenario.replaceAll(';' , '; ')}
                          </div>
                        </React.Fragment>
                      );
                    })}
                  </div>
                </React.Fragment>
              );
            })}
          </div>
          <div className={classNames.accordionDivision}
          />
          <div className={`${classNames.accordianSection}  accordion-investment-margin`}>
            <div>
              <strong>{labels.accordianWrapper.investmentCategories}</strong> {item?.investmentCategory}
            </div>
            <div>
              <strong>{labels.accordianWrapper.initiativeName}</strong>{labels.accordianWrapper.space}
              <>
              <a
                  href={`${RoadmapURL}${epic?.initiativeId}`}
                  target={StringLabel?.Blank}
                >
                  {epic?.initiativeId}
                </a>{labels.accordianWrapper.space}{item?.initiativeName} 
               
              </>
            </div>
            <div>
              <strong>{labels.accordianWrapper.solutionArea}</strong> {epic?.solutionArea}
            </div>
            <div>
              <strong>{labels.accordianWrapper.aiFlag}</strong> {epic?.ai}
            </div>
            <div>
              <strong>{labels.accordianWrapper.changeType}</strong> {epic?.changeType}
            </div>
            <div>
              <strong>{labels.accordianWrapper.corePriority}</strong> {epic?.corePriority}
            </div>
          </div>
        </div>
      </AccordionPanel>
    </AccordionItem>
  );
};

export default AccordianWrapper;
