import React from "react";
import { classNames } from "./common.style";

const Pagination = ({ currentPage, handlePrevPageClick, pageNumbers, handlePageClick, handleNextPageClick }: {
    currentPage: number,
    handlePrevPageClick: (event: React.MouseEvent<HTMLAnchorElement>) => void,
    handleNextPageClick: (event: React.MouseEvent<HTMLAnchorElement>) => void,
    handlePageClick: (event: React.MouseEvent<HTMLAnchorElement>) => void,
    pageNumbers: number[]
}) => <div className={classNames.pagination}>
        {currentPage > 1 && (
            <span
                className={`${classNames.pageNumbersItemNext} ${classNames.pItem}`}
                onClick={handlePrevPageClick}
            >{`<`}</span>
        )}
        {pageNumbers &&
            pageNumbers.map((index: number) => (
                <span key={index}>
                    <a
                        className={
                            currentPage === index
                                ? `${classNames.selectedPageNumbersItem} ${classNames.pItem}`
                                : `${classNames.pageNumbersItem} ${classNames.pItem}`
                        }
                        key={index}
                        id={index.toString()}
                        onClick={handlePageClick}
                    >
                        {index}
                    </a>
                </span>
            ))}
        {currentPage < pageNumbers.length && (
            <span
                className={`${classNames.pageNumbersItem} ${classNames.pItem}`}
                onClick={handleNextPageClick}
            >
                {">"}
            </span>
        )}
    </div>

export default Pagination;
