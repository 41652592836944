import { makeStyles } from "@fluentui/react-components";
import { StringLabel, Themes } from "../common/constants"
import { currentTheme } from "../common/types";
import { Feature } from "../model";
import { isEdge } from "../common/utils";

export const classNames = {
  square: "square",
  colsFilter: "cols-filter",
  mainBox: "main-box",
  tableWrapper: "table-wrapper",
  fHeader: "f-header",
  fName: "f-name",
  fRow: "f-row",
  formatColumn: "format-column",
  divRow: "div-Row",
  accordian: "accordian",
  flex: "flex",
  allignItemCenter: "align-items-center",
  w100: "w-100",
  accordianHeader: "accordian-header",
  preview: "preview",
  accordianBody: "accordian-body",
  accordianSection: "accordian-section",
  circle: "circle",
  statusMargin: "status-margin",
  epicMargin: "epic-margin",
  accordionDivision: "accordian-division",
  belowStart: "below-start",
  fitContent: "fit-content",
  filterFields: "filters-fields",
  clrBtn: "clear-btn",
  header: "header",
  usingThisRoadMap: "using-this-roadmap",
  primary: "primary",
  mainheader: "mainheader",
  mslogo: "mslogo",
  userName: "userName",
  pagination: "pagination",
  pageNumbersItemNext: "pageNumbersItemNext",
  pItem: "p-item",
  selectedPageNumbersItem: "selectedPageNumbersItem",
  pageNumbersItem: "pageNumbersItem",
  sortWidth: "sort-width",
  allignSort: "allign-sort",
  container: "container",
  spinnerMargin: "spinner-margin",
  filterWrapper: "filters-wrapper",
  search: "search",
  checkBoxLeft: "checkbox-left",
  filters: "filters",
  flexItem: "flexItem",
  filterColor: "filter-color",
  featureMargin: "feature-margin"
}

export const useStyles = makeStyles({
  listbox: {
      maxHeight: "400px",
      transform: "translate(0px, 31px) !important"
  }
});

export const getModernUiStyle = (columns: string[], currentTheme: currentTheme ) => {
   
    return {
        th: {
            backgroundColor: Themes[currentTheme].mainHeader,
          },
          triangleA: {
            background: "#FFF",
            position: "absolute",
            right: 0,
            top: 0,
            borderTop: "13px solid transparent",
            borderLeft: `15px solid ${Themes[currentTheme].mainHeader}`,
            borderBottom: "13px solid transparent",
          } as React.CSSProperties,
          triangleB: {
            background: Themes[currentTheme].mainHeader,
            position: "absolute",
            left: "-5px",
            top: 0,
            borderTop: "13px solid transparent",
            borderLeft: `15px solid #FFF`,
            borderBottom: "13px solid transparent",
          } as React.CSSProperties,
          header: {
            backgroundColor: Themes[currentTheme].header,
          },
          roadmapItem: {
            backgroundColor: Themes[currentTheme].roadmapItem,
            width: columns.length > 4 ? "20%" : "25%",
            textAlign: "left",
          } as React.CSSProperties,
          targetRelease: {
            backgroundColor: Themes[currentTheme].targetRelease,
            width: getWidth(columns),
            textAlign: "left",
          } as React.CSSProperties,
          quarterTitle: {
            backgroundColor: Themes[currentTheme].targetRelease,
            textAlign: "left",
          },
          marginRight: { marginRight: ".5rem" }
    }
  }

  const getWidth = (columns: string[]) => {
    if (columns.length === 1) {
      return "70%";
    } else if (columns.length === 2) {
      return "35%";
    }
    return columns.length > 4 ? "14%" : "20%";
  }

  export const getColumnWidth = (columns: string[], columnData: Feature[], currentTheme: currentTheme) => {
    return {
        backgroundColor: columnData.length > 1 ? "white" : Themes[currentTheme].targetRelease,
        width: columns.length > 4 ? "14%" : "20%",
      }
  }

  export const getMultipleFeatureUI = (feature: Feature, columns: string[], currentTheme: currentTheme) => {
    return {
        backgroundColor: Themes[currentTheme].targetRelease,
        borderBottom: feature ? "5px solid white" : StringLabel.EmptyString
      }
  }

  export const getFilterStyle = (filterArray: any = []) => {
    return {
      filterArrayButton: {
        color: filterArray?.length > 0 ? "white" : StringLabel?.EmptyString
        },
       filterGap: { gap: 5 },
       filterMargin: { gap: 5, marginTop: 5 },
       clearBtn: !isEdge() ? { minWidth: 40 } : {},

  }
  }
  export const getMinWidth = () => {
    return { minWidth: 'fit-content' }
  }
  
  export const getSearchboxWidth = () => {
    return { width: 250 }
  }
  
  