import React from 'react';
import logo from '../assets/mslogo.png';
import { Persona } from '@fluentui/react-components';
import labels from '../common/FTE.string';
import { classNames } from './common.style';
 
interface MainHeaderProps {
    userName: string;
    loginName:string
}
 
interface MainHeaderState {
    count: number;
}
 
class MainHeader extends  React.Component<MainHeaderProps, MainHeaderState> {
    constructor(props: MainHeaderProps) {
        super(props);
        this.state = {
            count: 0,
        };
    }
 
    render() {
        const { userName,loginName } = this.props;
        return (
            <>
            <div className={classNames.mainheader}>
                <div className={classNames.mslogo}>
                    <img src={logo} alt={labels.microsoftLogo}  />                    
                </div>
                <div className={classNames.userName}>
                <Persona
                    size="medium"
                    name={userName}
                    avatar={{ color: "colorful" }}
                    secondaryText={loginName}                  
                />
                </div>
            </div>
            </>
        );
    }
 
    incrementCount = () => {
        this.setState((prevState) => ({
            count: prevState.count + 1,
        }));
    };
}
 
export default MainHeader;