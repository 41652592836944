export class initiative {
  public id!: string;
  public investmentCategory!: string;
  public initiativeName!: string;
}

export class Feature {
  public id!: number;
  public epicId!: number;
  public financialYear!: string;
  public financialQuarter!: string;
  public scope!: string;
  public descriptor!: string;
  public engPillar!: string;
  public workloadScenario!: string;
  public ai!: string;
  public state!: string;
}

export class Epic {
  public id!: number;
  public initiativeId!: number;
  public roadmapItemName!: string;
  public financialYear!: string;
  public financialQuarter!: string;
  public changeType!: string;
  public ai!: string;
  public solutionArea!: string;
  public state!: string;
  public features!: Feature[];
  public corePriority!: string;
}

export class RoadMapItem {
  public id!: number;
  public investmentCategory!: string;
  public initiativeName!: string;
  public lastRunDateTime!: string;
  public epics!: Epic[];
}

export class RoadMapList {
    public RoadMaps?: RoadMapItem[];
}

export enum AuthConfig {
    ClientId = "045fb010-889c-4ea0-9e85-a13eeba68429",
    Authority = "https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47",
    RedirectUri = "https://ftengineeringroadmap-test.microsoft.com",
    Scope = "api://dd34f04f-3e17-4a27-ba09-0566e82cd25b/ftrmp.read",
  }
