import React, { SyntheticEvent } from "react";
import {
  Accordion,
  Checkbox,
  SearchBox,
  Spinner,
  Switch,
} from "@fluentui/react-components";

import { withMsal } from "@azure/msal-react";

import { getRoadMap } from "./api";
import { AuthConfig, Epic, Feature, RoadMapItem, RoadMapList } from "./model";
import {
  DefaultFilters,
  EpicFilters,
  InvestmentCategory,
  FeatureFilters,
  TargetReleaseLabel,
  StringLabel,
  AllStatus,
} from "./common/constants";
import {
  filterArrayByStatus,
  filterEpicData,
  filterFeatureData,
  filterRoadMapItems,
  getFilterArrayByKeys,
  getFormatedDate,
  getFormatedQuarter,
  getstatusCount,
  replaceValuesWithEnum,
  setColumn,
  sortRoadMapItems,
} from "./common/utils";
import { HomeProps, HomeState, currentTheme, statusFilter } from "./common/types";
import Header from "./components/Header";
import Sort from "./components/Sort";
import Pagination from "./components/Pagination";
import MainHeader from "./components/MainHeader";
import ThemeSelector from "./components/ThemeSelector";
import AccordianWrapper from "./components/AccordianWrapper";
import ModernUI from "./components/ModernUI";
import ColumnSelector from "./components/ColumnSelector";
import Filters from "./components/Filters";
import labels from "./common/FTE.string";
import { classNames, getSearchboxWidth } from "./components/common.style";

class HomeC extends React.Component<HomeProps, HomeState> {
  constructor(props: HomeProps | any) {
    super(props);
    this.state = {
      roadMap: new RoadMapList(),
      allRecords: new RoadMapList(),
      pageNumbers: [],
      currentPage: Number(StringLabel.FirstPage),
      itemsPerPage: Number(StringLabel.ItemsPerPage),
      searchText: StringLabel.EmptyString,
      sortingOrder: StringLabel.SortingOrder,
      filtersList: DefaultFilters,
      checkedFilters: DefaultFilters,
      isLoading: false,
      accessToken: StringLabel.EmptyString,
      userName: StringLabel.EmptyString,
      loginName: StringLabel.EmptyString,
      wholeWordMatch: true,
      currentFilter: StringLabel.EmptyString,
      showModernUi: false,
      currentTheme: "Primary",
      columns: [],
      allColumns: [],
      statusChecked: AllStatus,
    };
  }

  async componentDidMount(): Promise<void> {
    const isAuthenticated = this.props.msalContext.accounts.length > 0;
    if (isAuthenticated) {
      const userName = this.props?.msalContext?.accounts[0]?.name as string;
      const loginName = this.props?.msalContext?.accounts[0]
        ?.username as string;
      this.setState({ userName, loginName });

      const accessTokenRequest = {
        scopes: [AuthConfig.Scope],
        account: this.props?.msalContext?.instance?.getAllAccounts()[0],
      };
      await this.props?.msalContext?.instance
        .acquireTokenSilent(accessTokenRequest)
        .then((response: any) => this.fetchData(response?.accessToken));
    }
  }

  componentDidUpdate(
    prevProps: Readonly<{}>,
    prevState: Readonly<HomeState>,
    snapshot?: any
  ): void {
    const { checkedFilters, currentFilter } = this.state;
    const columnChanges = this.state.columns !== prevState.columns;
    if ((prevState?.checkedFilters !== checkedFilters) || (this.state.statusChecked !== prevState.statusChecked) || columnChanges) {
      this.setState({ roadMap: this.state.allRecords });
      const totalNoOfFilters = Object.keys(checkedFilters)
        .map((a) => (checkedFilters as { [key: string]: any })[a].length)
        .reduce((a, b) => a + b, 0);

      const checkedStatusLabels = this.state.statusChecked
        .filter(status => status.checked).flatMap(status => status.statusList);

      if (totalNoOfFilters === 0 && checkedStatusLabels.length === 0 && !columnChanges) return;
      let filteredData = this.filterInvestmentCategory(
        this.state.allRecords?.RoadMaps as RoadMapItem[],
        checkedFilters
      );

      filteredData = filteredData.filter((item) => {
        if (checkedFilters?.initiativeName?.length === 0) return true;
        return checkedFilters?.initiativeName?.includes(item?.initiativeName);
      });

      EpicFilters?.forEach((key) => {
        filteredData = filterEpicData(
          filteredData as RoadMapItem[],
          checkedFilters,
          key
        );
      });

      FeatureFilters?.forEach((key) => {
        filteredData = filterFeatureData(
          filteredData as RoadMapItem[],
          checkedFilters,
          key
        );
      });
      if (this.state.showModernUi && columnChanges) {
        filteredData = filteredData?.map((item) => ({
          ...item,
          epics: item?.epics
            ?.map((epic) => ({
              ...epic,
              features: epic?.features?.filter((feature) => {
                const yearQuarter = getFormatedQuarter(feature);
                return this.state.columns?.includes(yearQuarter);
              }),
            }))
            .filter((epic) => epic?.features?.length > 0),
        })).filter((item) => item?.epics?.length > 0);
      }
      if(checkedStatusLabels.length > 0)
        filteredData = filterArrayByStatus(filteredData, checkedStatusLabels);
      
      this.resetFilters(filteredData as RoadMapItem[], currentFilter);

      this.setState({
        roadMap: { RoadMaps: filteredData },
        currentPage: Number(StringLabel?.FirstPage),
      });
    }
  }

  private filterInvestmentCategory = (
    roadMap: RoadMapItem[],
    checkedFilters: any
  ) => {
    let otherFilterData: RoadMapItem[] = [];
    if (checkedFilters?.investmentCategory?.length === 0) return roadMap;
    if (checkedFilters.investmentCategory.includes("Other")) {
      otherFilterData = roadMap?.filter(
        (item) =>
          !item.investmentCategory
            .split(";")
            .some((value) =>
              Object.values(InvestmentCategory).includes(
                value.trim() as InvestmentCategory
              )
            )
      );
    }
    let filteredData = roadMap?.filter((item: RoadMapItem) => {
      if (checkedFilters?.investmentCategory?.length === 0) return true;
      return checkedFilters?.investmentCategory?.some((filter: any) =>
        item?.investmentCategory
          ?.split(";")
          .map((item: any) => item.trim())
          .includes(filter)
      );
    });
    return [...otherFilterData, ...filteredData];
  };

  private fetchData = (authToken: string) => {
    this.setState({ isLoading: true });
    getRoadMap(authToken)
      .then((data) => {
        this.setState({ isLoading: false });
        const roadMapList: RoadMapList = new RoadMapList();
        roadMapList.RoadMaps = data;
        roadMapList.RoadMaps = roadMapList?.RoadMaps?.map(
          (item: RoadMapItem) => {
            item.investmentCategory = replaceValuesWithEnum(
              item?.investmentCategory.replace("#", "") || "",
              InvestmentCategory
            );

            item.epics = item?.epics?.filter(
              (epic: Epic) =>
                epic?.roadmapItemName !== null && epic?.roadmapItemName !== StringLabel.EmptyString
            );

            return item;
          }
        );

        const allColumns = setColumn(roadMapList as RoadMapList);
        this.setState(
          {
            roadMap: roadMapList,
            allRecords: roadMapList,
            allColumns: allColumns.allColumns,
            columns: allColumns.columns,
          },
          () => this.resetFilters(roadMapList?.RoadMaps as RoadMapItem[])
        );
        const pageNumbers: number[] = [];

        const totalEpics = roadMapList.RoadMaps.reduce(
          (total, roadMap) => total + roadMap.epics.length,
          0
        );
        for (
          var i = 1;
          i <= Math.ceil(totalEpics / this.state?.itemsPerPage);
          i++
        ) {
          pageNumbers.push(i);
        }

        this.setState({ pageNumbers: pageNumbers });
      })
      .catch(() => this.setState({ isLoading: false }));
  };

  private onClickFilter = (
    ev: SyntheticEvent,
    data: string[],
    category: string
  ) => {
    this.setState(
      (prevState) => ({
        currentFilter: category,
        checkedFilters: {
          ...prevState?.checkedFilters,
          [category]: data,
        },
      }),
      () => {
        if (Object.values(this.state?.checkedFilters).flat().length === 0) {
          this.resetFilters(this.state?.allRecords?.RoadMaps as RoadMapItem[]);
        }
      }
    );
  };

  private onClickColumn = (ev: SyntheticEvent, data: string[]) => {
    this.setState({
      columns: data,
      currentPage: Number(StringLabel?.FirstPage),
    });
  };

  private clearAll = () => {
    this.setState({
      checkedFilters: DefaultFilters,
      statusChecked: AllStatus
    });
    this.resetFilters(this.state.allRecords.RoadMaps as RoadMapItem[]);
  };

  private resetFilters = (
    data: RoadMapItem[],
    currentFilter = StringLabel.EmptyString
  ) => {
    const filtersData = getFilterArrayByKeys(
      data as RoadMapItem[],
      Object.keys(DefaultFilters),
      currentFilter,
      this.state?.filtersList
    );

    this.setState({
      filtersList: {
        ...filtersData,
      },
    });
  };

  private renderRows = (items: any, itemsPerRow: any) => {
    // Split the items into rows
    const rows = [];
    for (let i = 0; i < items.length; i += itemsPerRow) {
      rows.push(items.slice(i, i + itemsPerRow));
    }
    return rows;
  }

  render() {
    const {
      roadMap,
      currentPage,
      itemsPerPage,
      filtersList,
      checkedFilters,
      isLoading,
      sortingOrder,
      searchText,
      wholeWordMatch,
      showModernUi,
      currentTheme,
      columns,
      allColumns,
      allRecords,
    } = this.state;

    const pageNumbers: number[] = [];
    let filterItemsLength: number = 0;

    let filterItems: RoadMapItem[] = [];
    let allEpics: Epic[] = [];
    let allFeatures: Feature[] = [];
    const itemsPerRow = 6; // Number of items per row
  
    const rows = this.renderRows(this.state.statusChecked, itemsPerRow);

    if (roadMap && roadMap?.RoadMaps && roadMap?.RoadMaps?.length >= 0) {
      filterItems = roadMap?.RoadMaps;
      filterItems = sortRoadMapItems(filterItems, sortingOrder) || [];
    }

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    if (roadMap && roadMap?.RoadMaps && roadMap?.RoadMaps?.length >= 0) {
      if (searchText && searchText?.length > 0) {
        filterItems = filterRoadMapItems(
          filterItems,
          searchText,
          wholeWordMatch
        );
      }

      allEpics = filterItems.flatMap((roadMap) => roadMap.epics);

      if (allEpics?.length < itemsPerPage) {
        pageNumbers?.push(1);
      } else {
        for (
          var i = 1;
          i <= Math.ceil(allEpics?.length / this.state?.itemsPerPage);
          i++
        ) {
          pageNumbers?.push(i);
        }
      }
      
      filterItemsLength = allEpics.flatMap((epic) => epic.features).length;
      const epics = this.state.allRecords?.RoadMaps?.flatMap((roadMap) => roadMap.epics);
      allFeatures = allEpics?.flatMap((epic) => epic.features) || [];

      allEpics = allEpics.slice(indexOfFirstItem, indexOfLastItem);
    }

    return (
      <div className="container">
        <MainHeader
          userName={this.state.userName}
          loginName={this.state.loginName}
        />
        <Header />

        {isLoading ? (
          <Spinner className={classNames.spinnerMargin} />
        ) : (
          <div className={classNames.mainBox}>
            <div className={classNames.filterWrapper}>
              <div className={classNames.search}>
                <h2>{labels.searchString}</h2>
                <SearchBox
                  style={getSearchboxWidth()}
                  placeholder="Search"
                  onReset={() => {
                    this.setState({ searchText: StringLabel.EmptyString });
                  }}
                  onChange={(event) => {
                    this.setState({
                      searchText: (event.currentTarget as HTMLInputElement)
                        .value,
                    });
                  }}
                />
                <div>
                  <Checkbox
                    className={classNames.checkBoxLeft}
                    label={labels.wholeWordMatch}
                    onChange={this.setWholeWordMatch}
                    checked={wholeWordMatch}
                  />
                </div>
              </div>
              <div className={classNames.filters}>
                <div className={classNames.flexItem}>
                  <h2>{labels.filterLabel}</h2>{" "}
                  <div>
                    <strong>{labels.LastUpdated} : </strong>
                    <span>
                      {getFormatedDate(
                        allRecords?.RoadMaps?.[0]?.lastRunDateTime ??
                          StringLabel.EmptyString
                      )}
                    </span>
                  </div>
                </div>

                <Filters
                  onClickFilter={this.onClickFilter}
                  filtersList={filtersList}
                  checkedFilters={checkedFilters}
                  clearAll={this.clearAll}
                  isPresentationView={showModernUi}
                />
              </div>
            </div>
            <div className={classNames.flexItem}>
              <div>
                <strong>
                  Showing{labels.accordianWrapper.space}
                  <span className={classNames.filterColor}>
                    {filterItemsLength}
                  </span>{" "}
                  updates:
                </strong>
              </div>

              <div className={classNames.flexItem}>
                {showModernUi && (
                  <ColumnSelector
                    targetReleaseFilters={[...new Set([...allColumns])]}
                    onClickColumn={this.onClickColumn}
                    selectedColumns={columns}
                  />
                )}
                &nbsp;
                {showModernUi && (
                  <ThemeSelector
                    currentTheme={currentTheme}
                    onSelectTheme={this.onSelectTheme}
                  />
                )}
                <Switch
                  label={showModernUi ? labels.switchDetailView: labels.switchPresentationView}
                  onChange={this.setModernUi}
                  checked={showModernUi}
                />
              </div>
            </div>
            <hr/> 
            <div>
            <div className="status-container">
              {rows.map((row, rowIndex) => {
                // Ensure there are 6 columns in each row
                const columns = [...row];
                while (columns.length < 6) {
                  columns.push({ label: `extra-${columns.length}`, checked: false, color: 'transparent', status: '' });
                }

                return (
                  <div className="status-row" key={`row-${rowIndex}`}>
                    {columns.map((key, index) => (
                      <div
                        className="status"
                        key={key.label}
                      >
                        <div className="status-align">
                          <Checkbox
                            style={{ verticalAlign: "inherit", visibility: key.status == '' ? 'hidden' : 'visible' }}
                            className="checkbox-status"
                            checked={key.checked}
                            onChange={(ev, checked) => this.onStatusChange(key, checked)}
                          />
                          <div className="status-box" style={{ backgroundColor: key.color }}></div>
                          <span className="status-count" style={{ visibility: key.status == '' ? 'hidden' : 'visible' }}>{getstatusCount(allFeatures, key.statusList)}</span>
                          <span className="label">{key.status || ''}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                );
              })}
            </div>
            </div>
            <hr/> 
          </div>
        )}

        {allEpics.length > 0 ? (
          showModernUi ? (
            <ModernUI
              checkedFilters={checkedFilters}
              filterItems={filterItems}
              currentTheme={currentTheme}
              columns={columns.sort()}
              allEpics={allEpics}
            />
          ) : (
            <div className="main">
              <div className="main-box">
                <div className="sort-wrapper">
                  <h2>{labels.initiativeNameHeading}</h2>
                  <Sort sortingOrder={sortingOrder} onSort={this.onSort} />
                </div>
                <div className="accordians">
                  <Accordion collapsible>
                    {allEpics.map((epic: Epic, itemIndex: number) => {
                      return (
                        <AccordianWrapper
                          key={`${itemIndex}}`}
                          value={`${itemIndex}}`}
                          item={
                            allRecords?.RoadMaps?.find(
                              (item: RoadMapItem) =>
                                item.id === epic.initiativeId
                            ) as RoadMapItem
                          }
                          epic={epic}
                          checkedFilters={checkedFilters}
                        />
                      );
                    })}
                  </Accordion>
                </div>
              </div>
            </div>
          )
        ) : (
          !isLoading && <div className="err-msg">{labels.noDataFound}</div>
        )}

        {filterItemsLength > 0 && (
          <Pagination
            currentPage={this.state.currentPage}
            handlePrevPageClick={this.handlePrevPageClick}
            pageNumbers={pageNumbers}
            handlePageClick={this.handlePageClick}
            handleNextPageClick={this.handleNextPageClick}
          />
        )}
      </div>
    );
  }
  onStatusChange(key: statusFilter, checked: any): void {
    this.setState(prevState => ({
      statusChecked: prevState.statusChecked.map(cb =>
        cb.status === key.status ? { ...cb, checked: !key.checked } : cb
      ),
    }));
  }

  private onSelectTheme = (theme: currentTheme) => {
    this.setState({
      currentTheme: theme,
    });
  };

  private setModernUi = () => {
    this.resetFilters(this.state.allRecords.RoadMaps as RoadMapItem[]);
    this.setState((prevState) => ({
      showModernUi: !prevState.showModernUi,
      checkedFilters: {
        ...prevState.checkedFilters,
        [TargetReleaseLabel]: [],
      },
    }));
  };

  private setWholeWordMatch = () => {
    this.setState((prevState) => ({
      wholeWordMatch: !prevState.wholeWordMatch,
    }));
  };

  private onSort = (value: string) => {
    this.setState({ sortingOrder: value as string });
  };

  private handlePageClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    const target = event.target as HTMLAnchorElement;
    const id = target.id;
    this.setState({ currentPage: parseInt(id) });
  };

  private handlePrevPageClick = (
    event: React.MouseEvent<HTMLAnchorElement>
  ) => {
    const currentPage = this.state.currentPage;
    if (this.state.currentPage === 1) {
      return;
    }
    const id = currentPage - 1;
    this.setState({ currentPage: id });
  };

  private handleNextPageClick = (
    event: React.MouseEvent<HTMLAnchorElement>
  ) => {
    const currentPage = this.state?.currentPage;
    if (this.state?.currentPage === this.state?.pageNumbers?.length) {
      return;
    }
    const id = currentPage + 1;
    this.setState({ currentPage: id });
  };
}

const Home = withMsal(HomeC);
export default Home;
