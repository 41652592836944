import {
    Button,
    Option,
    Dropdown,
    PositioningShorthand,
} from "@fluentui/react-components";
import { FiltersArray, TargetReleaseLabel } from "../common/constants";
import { CustomFilterProps, DropdownWrapperProps } from "../common/types";
import { classNames, getFilterStyle, getMinWidth, useStyles } from "./common.style";
import labels from "../common/FTE.string";

const DropdownWrapper = ({ checkedFilters, filtersList, onClickFilter, fKey, fLabel, isPresentationView, ...props }: DropdownWrapperProps) => {
    let thisFilterArray = (checkedFilters as { [key: string]: any })[fKey];

    const styles = useStyles();
    return <Dropdown
        disabled={isPresentationView && fKey === TargetReleaseLabel}
        multiselect
        listbox={{ className: styles.listbox }}
        button={<span style={getFilterStyle(thisFilterArray).filterArrayButton}>
            {fLabel}
        </span>}
        positioning={classNames.belowStart as PositioningShorthand}
        placeholder={fLabel}
        inlinePopup
        style={getMinWidth()}
        onOptionSelect={(ev, data) => onClickFilter(ev, data.selectedOptions, fKey)}
        selectedOptions={(checkedFilters as { [key: string]: any })[fKey]}
        className={thisFilterArray.length > 0 ? fKey + ` ${labels.filter.selected}` : fKey}
    >
        {props.children}
    </Dropdown>
}

const Filters = ({ onClickFilter, filtersList, checkedFilters, clearAll, isPresentationView }: CustomFilterProps) => (
    <>
        <div style={getFilterStyle().filterGap} className={classNames.filterFields}>
            {FiltersArray.slice(0, 4).map((f, i) => (
                <DropdownWrapper
                    key={i}
                    isPresentationView={isPresentationView}
                    checkedFilters={checkedFilters}
                    filtersList={filtersList}
                    onClickFilter={onClickFilter}
                    fKey={f.fKey}
                    fLabel={f.fLabel}
                >
                    {(filtersList as { [key: string]: any })[f.fKey].map((option: any) => (
                        <Option key={option}>
                            {option}
                        </Option>
                    ))}
                </DropdownWrapper>
            ))}
        </div>
        <div style={getFilterStyle().filterMargin} className={classNames.filterFields}>
            {FiltersArray.slice(4).map((f, i) => (
                <DropdownWrapper
                    key={i}
                    isPresentationView={isPresentationView}
                    checkedFilters={checkedFilters}
                    filtersList={filtersList}
                    onClickFilter={onClickFilter}
                    fKey={f.fKey}
                    fLabel={f.fLabel}
                >
                    {(filtersList as { [key: string]: any })[f.fKey].map((option: any) => (
                        <Option key={option}>
                            {option}
                        </Option>
                    ))}
                </DropdownWrapper>
            ))}

        <Button onClick={clearAll} style={getFilterStyle().clearBtn} className={classNames.clrBtn}>Clear all</Button>
        </div>
    </>
);

export default Filters;